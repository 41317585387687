import styled from 'styled-components';

export const Container = styled.div<{ reversed: boolean }>`
  display: flex;
  padding-inline-start: 1.125rem;
  padding-inline-end: 1rem;
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  align-items: center;
  background: ${p =>
    p.reversed
      ? p.theme.token('background-hero-reversed')
      : p.theme.token('background-hero-light')};
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : p.theme.token('text-default'))};

  @media only screen and (max-width: 432px) {
    max-height: 20px;
  }
`;
export const ClickableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  height: 36px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  ${Styles.desktop} {
    font-size: 0.983rem;
  }
`;
export const Icon = styled.div`
  margin-inline-end: 0.5rem;
`;
export const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
export const Heading = styled.div`
  font-family: ${Styles.fontFamily.base};
  white-space: nowrap;
  font-size: 1rem;
  ${Styles.desktop} {
    font-size: 1.125rem;
  }
`;
export const Details = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-inline-start: 0.5rem;
  max-width: 57vw;
  font-size: 0.937rem;
  width: 9.5rem;
  ${Styles.desktop} {
    margin-inline-end: 0.5rem;
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
`;
