import { TLocaleCode } from 'types/i18n';

type RouteOverrides = Partial<Record<TLocaleCode, Record<string, string>>>;

export const routeOverrides: RouteOverrides = {
  'en-GB': {
    '/support': '/get-in-touch',
  },
  'de-DE': {
    '/support': '/kontakt',
  },
};
