import React, { FC } from 'react';

import styled from 'styled-components';

import { DesktopRaggedEdge } from 'components/icons/ragged-edge';
import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { Row } from './shared';
import { IFooterProps } from './types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.normal};
  letter-spacing: normal;

  ${Styles.desktopLarge} {
    display: flex;
    width: 100%;
    margin-block-start: -13px;
    z-index: ${Styles.zIndex.top};
  }
`;

const EdgeContainer = styled.div`
  height: 13px;
  width: 100%;
`;

const ContentContainer = styled.div`
  background-color: ${Styles.color.white};
  color: ${Styles.color.grey.one};
  width: 100%;
  padding-block-start: 1rem;
  padding-block-end: 2rem;
  padding-inline: 1rem;
  ${Styles.desktopLarge} {
    color: ${Styles.color.white};
    background-color: ${Styles.color.grey.one};
    padding-block-start: 48px;
    padding-block-end: 40px;
    padding-inline: 64px;
  }
  ${Styles.desktopLarge} {
    padding-block-start: 90px;
    padding-block-end: 50px;
    padding-inline: 120px;
  }
`;

const FooterContent: FC<IFooterProps> = props => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();
  const isMobile = useMediaQuery('headerMobile');
  const enableMobileFooter = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_FOOTER);

  return featureFooterLoading || (isMobile && !enableMobileFooter) ? null : (
    <FooterContainer {...props} role="contentinfo">
      {!isMobile && (
        <EdgeContainer>
          <DesktopRaggedEdge />
        </EdgeContainer>
      )}
      <ContentContainer>
        {featureFooter?.rows?.map((row, index) => row && <Row key={row._key ?? index} {...row} />)}
      </ContentContainer>
    </FooterContainer>
  );
};

export default FooterContent;
