import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

export const StyledChooseLocation = styled.button<{ reversed: boolean }>`
  font-size: 0.875rem;
  text-decoration: underline;
  color: ${p => (p.reversed ? p.theme.token('text-reversed') : Styles.color.contrastBackground)};
  ${Styles.desktop} {
    font-size: 0.983rem;
    margin-inline-end: 1rem;
  }

  &:hover {
    text-decoration: none;
  }

  border: none;
  background: none;
  cursor: pointer;
  margin-block-start: -0.01rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
  padding: 0;
  font-family: ${Styles.fontFamily.body};
`;

const ChooseLocation = ({ reversed }: { reversed: boolean }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledChooseLocation reversed={reversed}>
      {formatMessage({ id: 'chooseYourLocation' })}
    </StyledChooseLocation>
  );
};

export default ChooseLocation;
